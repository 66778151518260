import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { calculateRSI, calculateMACD, calculateBollingerBands, calculateSMA } from './technicalIndicators';

// Helper functions
const getTimeframeData = (data, timeframe) => {
  const now = new Date();
  let cutoffDate;

  switch (timeframe) {
    case '1H':
      cutoffDate = new Date(now.getTime() - (60 * 60 * 1000));
      break;
    case '5H':
      cutoffDate = new Date(now.getTime() - (5 * 60 * 60 * 1000));
      break;
    case '1D':
      cutoffDate = new Date(now.getTime() - (24 * 60 * 60 * 1000));
      break;
    case '3M':
      cutoffDate = new Date(now.setMonth(now.getMonth() - 3));
      break;
    case '6M':
      cutoffDate = new Date(now.setMonth(now.getMonth() - 6));
      break;
    case '12M':
      cutoffDate = new Date(now.setMonth(now.getMonth() - 12));
      break;
    default:
      return data;
  }

  return data.filter(item => new Date(item.date) >= cutoffDate);
};

const calculateRecommendation = (data, timeframe) => {
  const relevantData = getTimeframeData(data, timeframe);
  
  if (!relevantData || relevantData.length === 0) {
    return 'neutral';
  }

  const prices = relevantData.map(item => item.close);
  const rsi = calculateRSI(prices).slice(-1)[0];
  const macd = calculateMACD(prices);
  const lastMACD = macd.histogram.slice(-1)[0];
  const bb = calculateBollingerBands(prices);
  const lastPrice = prices.slice(-1)[0];
  const lastBB = {
    upper: bb.upperBand.slice(-1)[0],
    middle: bb.middleBand.slice(-1)[0],
    lower: bb.lowerBand.slice(-1)[0]
  };
  const sma50 = calculateSMA(prices, 50).slice(-1)[0];
  const sma200 = calculateSMA(prices, 200).slice(-1)[0];

  let signalStrength = 0;

  // RSI Signal (-2 to +2)
  if (rsi > 70) signalStrength -= 2;
  else if (rsi > 60) signalStrength -= 1;
  else if (rsi < 30) signalStrength += 2;
  else if (rsi < 40) signalStrength += 1;

  // MACD Signal (-2 to +2)
  if (lastMACD > 0) signalStrength += 1;
  if (macd.macdLine.slice(-1)[0] > macd.signalLine.slice(-1)[0]) signalStrength += 1;
  else signalStrength -= 1;

  // Bollinger Bands Signal (-2 to +2)
  if (lastPrice > lastBB.upper) signalStrength -= 2;
  else if (lastPrice < lastBB.lower) signalStrength += 2;

  // Moving Averages Signal (-2 to +2)
  if (sma50 > sma200) signalStrength += 1;
  else signalStrength -= 1;

  // Volume Trend (if available)
  if (relevantData[0].volume) {
    const volumeAvg = relevantData.reduce((acc, cur) => acc + cur.volume, 0) / relevantData.length;
    const lastVolume = relevantData[relevantData.length - 1].volume;
    if (lastVolume > volumeAvg * 1.5) signalStrength += 1;
    else if (lastVolume < volumeAvg * 0.5) signalStrength -= 1;
  }

  // Convert signal strength to recommendation
  if (signalStrength >= 4) return 'strong buy';
  if (signalStrength >= 2) return 'buy';
  if (signalStrength <= -4) return 'strong sell';
  if (signalStrength <= -2) return 'sell';
  return 'neutral';
};

const InfoIcon = ({ onClick }) => (
  <button
    onClick={onClick}
    className="ml-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none"
    title="More Information"
  >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
    </svg>
  </button>
);

const InfoModal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="text-gray-600 dark:text-gray-300 space-y-2">
          {content}
        </div>
      </div>
    </div>
  );
};

const LoadingIndicator = () => (
  <div className="flex items-center justify-center p-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100"></div>
  </div>
);

const SignalIndicator = ({ type, value, signal, description, explanation }) => {
  const [showInfo, setShowInfo] = useState(false);

  const getSignalColor = (signal) => {
    switch (signal?.toLowerCase()) {
      case 'bullish':
      case 'oversold':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400';
      case 'bearish':
      case 'overbought':
        return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400';
      default:
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-400';
    }
  };

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-lg p-3 shadow-sm">
        <div className="flex justify-between items-center mb-1">
          <div className="flex items-center">
            <span className="text-sm font-medium text-gray-600 dark:text-gray-400">{type}</span>
            <InfoIcon onClick={() => setShowInfo(true)} />
          </div>
          <span className={`text-xs font-medium px-2 py-1 rounded ${getSignalColor(signal)}`}>
            {signal}
          </span>
        </div>
        <div className="text-sm text-gray-900 dark:text-gray-100">
          {typeof value === 'number' ? value.toFixed(2) : value}
          {description && (
            <span className="text-xs text-gray-500 dark:text-gray-400 ml-2">
              ({description})
            </span>
          )}
        </div>
      </div>
      <InfoModal
        isOpen={showInfo}
        onClose={() => setShowInfo(false)}
        title={`About ${type}`}
        content={
          <div>
            <p className="mb-3">{explanation}</p>
            <h4 className="font-semibold mb-2">Current Signal Explanation:</h4>
            <p>{getSignalExplanation(type, signal, value)}</p>
          </div>
        }
      />
    </>
  );
};

const getSignalExplanation = (type, signal, value) => {
  switch (type) {
    case 'RSI (14)':
      if (signal === 'Overbought') {
        return `The RSI value of ${value.toFixed(2)} is above 70, indicating the asset may be overbought. This suggests the asset might be overvalued and could experience a price correction.`;
      } else if (signal === 'Oversold') {
        return `The RSI value of ${value.toFixed(2)} is below 30, indicating the asset may be oversold. This suggests the asset might be undervalued and could experience a price increase.`;
      }
      return `The RSI value of ${value.toFixed(2)} is within normal range (30-70), indicating neutral momentum.`;
    
    case 'MACD':
      if (signal === 'Bullish') {
        return `The MACD histogram value of ${value.toFixed(2)} is positive, indicating bullish momentum. This suggests the short-term average is above the long-term average, potentially signaling an upward trend.`;
      }
      return `The MACD histogram value of ${value.toFixed(2)} is negative, indicating bearish momentum. This suggests the short-term average is below the long-term average, potentially signaling a downward trend.`;
    
    case 'Moving Averages':
      if (signal === 'Bullish') {
        return 'The 50-day moving average is above the 200-day moving average (Golden Cross), suggesting a potential long-term upward trend.';
      }
      return 'The 50-day moving average is below the 200-day moving average (Death Cross), suggesting a potential long-term downward trend.';
    
    default:
      return `Current value: ${value.toFixed(2)}`;
  }
};

const chartExplanations = {
  price: (
    <div>
      <p className="mb-2">This chart shows the asset's price movement along with several technical indicators:</p>
      <ul className="list-disc pl-4 space-y-2">
        <li><strong>Price Line:</strong> Shows the actual closing price of the asset over time.</li>
        <li><strong>Bollinger Bands:</strong> Three lines that help identify:
          <ul className="list-circle pl-4 mt-1">
            <li>Potential overbought/oversold conditions</li>
            <li>Price volatility (wider bands = higher volatility)</li>
            <li>Possible support/resistance levels</li>
          </ul>
        </li>
        <li><strong>Moving Averages:</strong>
          <ul className="list-circle pl-4 mt-1">
            <li>50-day SMA: Short-term trend indicator</li>
            <li>200-day SMA: Long-term trend indicator</li>
            <li>Crossovers can signal potential trend changes</li>
          </ul>
        </li>
      </ul>
    </div>
  ),
  rsi: (
    <div>
      <p className="mb-2">The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and magnitude of recent price changes:</p>
      <ul className="list-disc pl-4 space-y-2">
        <li><strong>Above 70:</strong> Considered overbought
          <ul className="list-circle pl-4 mt-1">
            <li>Asset might be overvalued</li>
            <li>Potential sell signal</li>
          </ul>
        </li>
        <li><strong>Below 30:</strong> Considered oversold
          <ul className="list-circle pl-4 mt-1">
            <li>Asset might be undervalued</li>
            <li>Potential buy signal</li>
          </ul>
        </li>
        <li><strong>Divergence:</strong> When price makes new highs/lows but RSI doesn't, it might indicate a potential trend reversal</li>
      </ul>
    </div>
  ),
  macd: (
    <div>
      <p className="mb-2">The Moving Average Convergence Divergence (MACD) helps identify trend direction, momentum, and potential reversals:</p>
      <ul className="list-disc pl-4 space-y-2">
        <li><strong>MACD Line (Blue):</strong> 
          <ul className="list-circle pl-4 mt-1">
            <li>Difference between 12 and 26-day EMAs</li>
            <li>Shows short-term momentum</li>
          </ul>
        </li>
        <li><strong>Signal Line (Red):</strong>
          <ul className="list-circle pl-4 mt-1">
            <li>9-day EMA of MACD line</li>
            <li>Used to generate trading signals</li>
          </ul>
        </li>
        <li><strong>Histogram:</strong>
          <ul className="list-circle pl-4 mt-1">
            <li>Shows difference between MACD and Signal lines</li>
            <li>Positive values suggest bullish momentum</li>
            <li>Negative values suggest bearish momentum</li>
          </ul>
        </li>
      </ul>
    </div>
  )
};

const TradingRecommendationGauge = ({ recommendation, timeframe }) => {
  const getColor = (rec) => {
    switch (rec.toLowerCase()) {
      case 'strong buy':
        return 'text-green-600 dark:text-green-500';
      case 'buy':
        return 'text-lime-600 dark:text-lime-500';
      case 'neutral':
        return 'text-yellow-600 dark:text-yellow-500';
      case 'sell':
        return 'text-orange-600 dark:text-orange-500';
      case 'strong sell':
        return 'text-red-600 dark:text-red-500';
      default:
        return 'text-gray-600 dark:text-gray-400';
    }
  };

  const getGaugePosition = (rec) => {
    switch (rec.toLowerCase()) {
      case 'strong sell': return '0%';
      case 'sell': return '25%';
      case 'neutral': return '50%';
      case 'buy': return '75%';
      case 'strong buy': return '100%';
      default: return '50%';
    }
  };

  return (
    <div className="flex flex-col items-center p-4 relative">
      <div className="text-sm font-medium text-gray-600 dark:text-gray-400 mb-3">
        {timeframe}
      </div>
      <div className="relative w-32 h-20">
        {/* Semicircle Background */}
        <div className="absolute bottom-0 w-full h-[80px] overflow-hidden">
          <div className="absolute w-[160px] h-[160px] bottom-0 left-1/2 -translate-x-1/2 rounded-full overflow-hidden">
            {/* Color segments */}
            <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 opacity-20"></div>
            <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 opacity-10"></div>
          </div>
        </div>

        {/* Indicator Needle */}
        <div 
          className="absolute bottom-0 left-1/2 w-1 h-16 bg-blue-600 origin-bottom transition-transform duration-500"
          style={{ 
            transform: `translateX(-50%) rotate(${parseInt(getGaugePosition(recommendation)) - 50}deg)`
          }}
        >
          <div className="absolute -top-1 left-1/2 w-3 h-3 bg-blue-600 rounded-full -translate-x-1/2"></div>
        </div>

        {/* Labels */}
        <div className="absolute -bottom-6 left-0 right-0 flex justify-between px-1 text-xs font-medium">
          <span className="text-red-600 dark:text-red-500">Strong<br/>Sell</span>
          <span className="text-orange-600 dark:text-orange-500">Sell</span>
          <span className="text-yellow-600 dark:text-yellow-500">Neutral</span>
          <span className="text-lime-600 dark:text-lime-500">Buy</span>
          <span className="text-green-600 dark:text-green-500">Strong<br/>Buy</span>
        </div>
      </div>

      {/* Recommendation Text */}
      <div className={`mt-8 font-semibold ${getColor(recommendation)} text-center`}>
        {recommendation.toUpperCase()}
      </div>

      {/* Tooltip */}
      <div className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-200">
        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded shadow-lg whitespace-nowrap">
          Based on RSI, MACD, and Bollinger Bands
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 transform rotate-45 w-2 h-2 bg-gray-900"></div>
        </div>
      </div>
    </div>
  );
};

const TechnicalCharts = ({ historicalData, symbol = 'Stock' }) => {
  const [activeModal, setActiveModal] = useState(null);

  const getMarketSummary = (chartData) => {
    const summaries = {
      price: () => {
        const lastPrice = chartData.prices[chartData.prices.length - 1];
        const lastBB = {
          upper: chartData.bollingerBands.upperBand[chartData.bollingerBands.upperBand.length - 1],
          middle: chartData.bollingerBands.middleBand[chartData.bollingerBands.middleBand.length - 1],
          lower: chartData.bollingerBands.lowerBand[chartData.bollingerBands.lowerBand.length - 1]
        };
        const lastSMA50 = chartData.sma50[chartData.sma50.length - 1];
        const lastSMA200 = chartData.sma200[chartData.sma200.length - 1];

        let bbStatus = '';
        if (lastPrice > lastBB.upper) {
          bbStatus = 'The price is above the upper Bollinger Band, suggesting overbought conditions.';
        } else if (lastPrice < lastBB.lower) {
          bbStatus = 'The price is below the lower Bollinger Band, suggesting oversold conditions.';
        } else {
          bbStatus = 'The price is within the Bollinger Bands, indicating normal trading conditions.';
        }

        const maStatus = lastSMA50 > lastSMA200 
          ? 'The 50-day moving average is above the 200-day moving average, suggesting a bullish trend (Golden Cross).'
          : 'The 50-day moving average is below the 200-day moving average, suggesting a bearish trend (Death Cross).';

        return (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg">
            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100 mb-2">Current Market Overview</h4>
            <div className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
              <p>{bbStatus}</p>
              <p>{maStatus}</p>
            </div>
          </div>
        );
      },

      rsi: () => {
        const lastRSI = chartData.rsiData[chartData.rsiData.length - 1];
        let analysis = '';
        if (lastRSI > 70) {
          analysis = 'The RSI is in overbought territory, suggesting the asset may be overvalued and could experience a price correction.';
        } else if (lastRSI < 30) {
          analysis = 'The RSI is in oversold territory, suggesting the asset may be undervalued and could experience a price increase.';
        } else {
          analysis = `The RSI is at ${lastRSI.toFixed(2)}, indicating neutral momentum conditions.`;
        }

        return (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg">
            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100 mb-2">RSI Analysis</h4>
            <p className="text-sm text-gray-600 dark:text-gray-300">{analysis}</p>
          </div>
        );
      },

      macd: () => {
        const lastHistogram = chartData.macdData.histogram[chartData.macdData.histogram.length - 1];
        const prevHistogram = chartData.macdData.histogram[chartData.macdData.histogram.length - 2];
        
        let analysis = '';
        if (lastHistogram > 0 && lastHistogram > prevHistogram) {
          analysis = 'MACD histogram is positive and increasing, indicating strengthening bullish momentum.';
        } else if (lastHistogram > 0) {
          analysis = 'MACD histogram is positive but decreasing, suggesting weakening bullish momentum.';
        } else if (lastHistogram < 0 && lastHistogram < prevHistogram) {
          analysis = 'MACD histogram is negative and decreasing, indicating strengthening bearish momentum.';
        } else {
          analysis = 'MACD histogram is negative but increasing, suggesting weakening bearish momentum.';
        }

        return (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg">
            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100 mb-2">MACD Analysis</h4>
            <p className="text-sm text-gray-600 dark:text-gray-300">{analysis}</p>
          </div>
        );
      }
    };

    return summaries;
  };

  const chartData = useMemo(() => {
    if (!historicalData || !Array.isArray(historicalData) || historicalData.length === 0) {
      return null;
    }

    try {
      const dates = historicalData.map(item => new Date(item.date).getTime());
      const prices = historicalData.map(item => parseFloat(item.close));
      
      if (prices.some(isNaN)) {
        console.error('Invalid price data detected');
        return null;
      }

      const rsiData = calculateRSI(prices);
      const macdData = calculateMACD(prices);
      const bollingerBands = calculateBollingerBands(prices);
      const sma50 = calculateSMA(prices, 50);
      const sma200 = calculateSMA(prices, 200);

      const lastRSI = rsiData[rsiData.length - 1] || 0;
      const lastMACD = macdData.histogram[macdData.histogram.length - 1] || 0;
      const lastSMA50 = sma50[sma50.length - 1] || 0;
      const lastSMA200 = sma200[sma200.length - 1] || 0;

      return {
        dates,
        prices,
        rsiData,
        macdData,
        bollingerBands,
        sma50,
        sma200,
        signals: {
          rsi: {
            value: lastRSI,
            signal: lastRSI > 70 ? 'Overbought' : lastRSI < 30 ? 'Oversold' : 'Neutral'
          },
          macd: {
            value: lastMACD,
            signal: lastMACD > 0 ? 'Bullish' : 'Bearish'
          },
          movingAverages: {
            signal: lastSMA50 > lastSMA200 ? 'Bullish' : 'Bearish',
            description: lastSMA50 > lastSMA200 ? 'Golden Cross' : 'Death Cross'
          }
        }
      };
    } catch (error) {
      console.error('Error processing chart data:', error);
      return null;
    }
  }, [historicalData]);

  if (!chartData) {
    return (
      <div className="text-center p-4 text-gray-500 dark:text-gray-400">
        Insufficient data for technical analysis
      </div>
    );
  }

  const commonOptions = {
    chart: {
      animations: { enabled: false },
      toolbar: { show: false },
      background: 'transparent',
      foreColor: '#9ca3af', // Light gray for better dark mode visibility
    },
    stroke: { curve: 'smooth' },
    xaxis: {
      type: 'datetime',
      categories: chartData.dates,
      labels: { 
        datetimeUTC: false,
        style: {
          colors: '#9ca3af' // Light gray for better visibility
        }
      },
      axisBorder: {
        color: '#374151' // Dark mode border color
      },
      axisTicks: {
        color: '#374151'
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#9ca3af' // Light gray for better visibility
        }
      }
    },
    grid: {
      borderColor: '#374151',
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true,
          opacity: 0.1
        }
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0.1
        }
      }
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px'
      },
      x: { 
        format: 'MMM dd yyyy',
        style: {
          colors: '#fff'
        }
      },
      y: {
        formatter: (value) => value?.toFixed(2)
      },
      marker: {
        show: true
      },
      items: {
        display: 'flex'
      },
      fixed: {
        enabled: false,
        position: 'topRight'
      }
    },
    legend: {
      show: true,
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      labels: {
        colors: '#9ca3af'
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 12,
        offsetX: -2,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 8,
        vertical: 8
      }
    }
  };

  const chartConfigs = {
    price: {
      options: {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          height: 400,
          toolbar: { 
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          }
        },
        colors: ['#3b82f6', '#ec4899', '#f59e0b', '#10b981', '#6366f1', '#9ca3af'], // Updated colors for better visibility
        yaxis: {
          labels: { 
            formatter: (value) => value ? `$${value.toFixed(2)}` : '$0.00',
            style: {
              colors: '#9ca3af'
            }
          },
          tooltip: { enabled: true }
        },
        markers: {
          size: 0,
          hover: {
            size: 4,
            sizeOffset: 3
          }
        }
      },
      series: [
        { name: 'Price', data: chartData.prices },
        { name: 'BB Upper', data: chartData.bollingerBands.upperBand },
        { name: 'BB Middle', data: chartData.bollingerBands.middleBand },
        { name: 'BB Lower', data: chartData.bollingerBands.lowerBand },
        { name: 'SMA 50', data: chartData.sma50 },
        { name: 'SMA 200', data: chartData.sma200 }
      ]
    },
    rsi: {
      options: {
        ...commonOptions,
        chart: { 
          ...commonOptions.chart, 
          height: 200,
          toolbar: { show: false }
        },
        colors: ['#3b82f6'], // Blue for RSI line
        yaxis: {
          min: 0,
          max: 100,
          labels: { 
            formatter: (value) => value ? value.toFixed(0) : '0',
            style: {
              colors: '#9ca3af'
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: 70,
              borderColor: '#ef4444',
              label: { 
                text: 'Overbought',
                style: {
                  color: '#fff',
                  background: '#ef4444'
                }
              }
            },
            {
              y: 30,
              borderColor: '#10b981',
              label: { 
                text: 'Oversold',
                style: {
                  color: '#fff',
                  background: '#10b981'
                }
              }
            }
          ]
        }
      },
      series: [{ name: 'RSI', data: chartData.rsiData }]
    },
    macd: {
      options: {
        ...commonOptions,
        chart: { 
          ...commonOptions.chart, 
          height: 200,
          toolbar: { show: false }
        },
        colors: ['#3b82f6', '#ec4899', '#10b981'], // Blue for MACD, Pink for Signal, Green for Histogram
        yaxis: {
          labels: { 
            formatter: (value) => value ? value.toFixed(2) : '0.00',
            style: {
              colors: '#9ca3af'
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '80%',
            colors: {
              ranges: [{
                from: -100,
                to: 0,
                color: '#ef4444'
              }, {
                from: 0,
                to: 100,
                color: '#10b981'
              }]
            }
          }
        },
        legend: {
          show: true,
          labels: {
            colors: '#9ca3af'
          }
        }
      },
      series: [
        { name: 'MACD', type: 'line', data: chartData.macdData.macdLine },
        { name: 'Signal', type: 'line', data: chartData.macdData.signalLine },
        { name: 'Histogram', type: 'bar', data: chartData.macdData.histogram }
      ]
    }
  };

  return (
    <div className="space-y-6">
      {/* 
        If you really have a TimeframeRecommendations component, import/define it before using.
        Otherwise remove this line. 
      */}
      {/* <TimeframeRecommendations symbol={symbol} historicalData={historicalData} /> */}

      {/* Technical Signals */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Technical Analysis Summary for {symbol}
          </h3>
          <InfoIcon onClick={() => setActiveModal('summary')} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <SignalIndicator
            type="RSI (14)"
            value={chartData.signals.rsi.value}
            signal={chartData.signals.rsi.signal}
            explanation="The Relative Strength Index (RSI) measures the speed and magnitude of recent price changes to evaluate overvalued or undervalued conditions."
          />
          <SignalIndicator
            type="MACD"
            value={chartData.signals.macd.value}
            signal={chartData.signals.macd.signal}
            explanation="The Moving Average Convergence Divergence (MACD) helps identify trend changes and momentum."
          />
          <SignalIndicator
            type="Moving Averages"
            value="SMA 50/200"
            signal={chartData.signals.movingAverages.signal}
            description={chartData.signals.movingAverages.description}
            explanation="The relationship between the 50-day and 200-day Simple Moving Averages (SMA) helps identify long-term trends."
          />
        </div>
      </div>

      {/* Price Chart */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Price with Technical Indicators
          </h3>
          <InfoIcon onClick={() => setActiveModal('price')} />
        </div>
        <ReactApexChart
          options={chartConfigs.price.options}
          series={chartConfigs.price.series}
          type="line"
          height={400}
        />
        {getMarketSummary(chartData).price()}
      </div>

      {/* RSI and MACD Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">RSI (14)</h3>
            <InfoIcon onClick={() => setActiveModal('rsi')} />
          </div>
          <ReactApexChart
            options={chartConfigs.rsi.options}
            series={chartConfigs.rsi.series}
            type="line"
            height={200}
          />
          {getMarketSummary(chartData).rsi()}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">MACD (12,26,9)</h3>
            <InfoIcon onClick={() => setActiveModal('macd')} />
          </div>
          <ReactApexChart
            options={chartConfigs.macd.options}
            series={chartConfigs.macd.series}
            type="line"
            height={200}
          />
          {getMarketSummary(chartData).macd()}
        </div>
      </div>

      {/* Info Modal */}
      <InfoModal
        isOpen={!!activeModal}
        onClose={() => setActiveModal(null)}
        title={`About ${activeModal === 'price' ? 'Price Chart' : activeModal === 'rsi' ? 'RSI' : 'MACD'}`}
        content={chartExplanations[activeModal]}
      />
    </div>
  );
};

// Export the component
export default TechnicalCharts;

// ----------------------------------
// Fix: Just remove or comment out this stray line:
// fix this error
// ----------------------------------
