import React, { useState, useCallback, useRef } from 'react';

// Move analysisTypes outside the component to prevent recreation
const analysisTypes = ['Warren Buffett', 'William ONeil', 'Benjamin Graham', 'Peter Lynch'];

// Mapping for backend keys - Update to match backend class names exactly
const analysisTypeKeys = {
  'Warren Buffett': 'Buffett',
  'William ONeil': 'WilliamONeilAnalysis',
  'Benjamin Graham': 'BenjaminGraham',
  'Peter Lynch': 'PeterLynch'
};

// Get progress bar color and label based on percentage
const getProgressInfo = (percentage) => {
  if (percentage >= 75) {
    return {
      color: 'bg-green-500 dark:bg-green-600',
      label: 'Strong Match',
      textColor: 'text-green-600 dark:text-green-500'
    };
  }
  if (percentage >= 50) {
    return {
      color: 'bg-yellow-500 dark:bg-yellow-600',
      label: 'Moderate Match',
      textColor: 'text-yellow-600 dark:text-yellow-500'
    };
  }
  return {
    color: 'bg-red-500 dark:bg-red-600',
    label: 'Weak Match',
    textColor: 'text-red-600 dark:text-red-500'
  };
};

// InfoCardWarren component
const InfoCardWarren = ({ title, loading, conditionsMet, totalConditions, error }) => {
  const percentage = totalConditions > 0 ? (conditionsMet / totalConditions) * 100 : 0;
  const { color, label, textColor } = getProgressInfo(percentage);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 h-full transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border border-gray-100 dark:border-gray-700">
      <div className="flex flex-col h-full">
        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-4">
          {title}
        </h3>
        {loading ? (
          <div className="flex-1 flex flex-col items-center justify-center space-y-3">
            <div className="animate-spin rounded-full h-8 w-8 border-3 border-primary border-t-transparent"></div>
            <span className="text-sm text-gray-500 dark:text-gray-400">Analyzing...</span>
          </div>
        ) : error ? (
          <div className="flex-1 flex items-center justify-center text-red-500 text-base">
            {error}
          </div>
        ) : (
          <div className="flex-1 flex flex-col justify-between space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  Match Score
                </div>
                <div className={`text-sm ${textColor} font-semibold`}>
                  {label}
                </div>
              </div>
              <div className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-4">
                {percentage.toFixed(1)}%
              </div>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-3">
                <div
                  className={`${color} h-3 rounded-full transition-all duration-500 ease-out`}
                  style={{ width: `${percentage}%` }}
                ></div>
              </div>
            </div>
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-600 dark:text-gray-400">
                Criteria Met: {conditionsMet}/{totalConditions}
              </span>
              <span className={`${textColor} font-semibold`}>
                {percentage.toFixed(0)}%
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Add analysis descriptions
const analysisDescriptions = {
    'Warren Buffett': `Warren Buffett's Investment Criteria:
    • Operating Margin Analysis (vs Historical Median)
    • Net Margin Analysis (vs Historical Median)
    • Liabilities to Assets Ratio ≤ 0.05
    • Five-Year EPS Growth ≥ 15%
    • Three-Year vs Five-Year Growth ≥ 15%
    • Positive Trailing Quarterly EPS
    • Five Years of Positive EPS
    • Return on Equity > 12%
    • Sustainable Growth Rate ≥ 15%

    Analysis focuses on consistent profitability, low debt, strong returns, and sustainable growth using historical and current data.`,

    'William ONeil': `William O'Neil's CANSLIM Criteria:
    • Current Price >= 20
    • Price within 15% of 52-week high
    • Return on Equity (ROE) TTM >= 17%
    • Relative Price Strength >= 80
    • Buyer Demand (A/D Rating) = A, B, or C
    • EPS Growth Rating >= 80

    Focus on price strength, institutional demand, and strong earnings growth using current market data.`,

    'Benjamin Graham': `Benjamin Graham's Value Criteria:
    • EPS Positive for Last 5 Years
    • EPS Positive for Last 4 Years
    • Positive Trailing 4 Quarters EPS
    • Price/Book Ratio > 0 and < 1.2
    • Current Ratio ≥ 1.5
    • Dividend Yield > 0
    • EPS (Trailing 4Q) > EPS (4 years ago)
    • EPS (Last FY) > EPS (4 years ago)
    • LT Debt to Working Capital > 0 and < 1.1
    • P/E Ratio < 10

    Conservative approach focusing on earnings stability, strong balance sheet, and reasonable valuations.`,

    'Peter Lynch': `Peter Lynch's Investment Criteria:
    • Not in Finance Sector
    • Not in Real Estate Development Industry
    • P/E Ratio < 20
    • P/E less than 5-year average
    • Dividend-Adjusted PEG ≥ 0.5
    • Positive 5-year EPS Growth
    • Institutional Holdings < 70%
    • Liabilities/Assets ratio < Industry Median

    Analysis focuses on avoiding certain sectors, reasonable valuations, sustainable growth, and healthy institutional ownership levels while maintaining strong balance sheet metrics.`
};

const CardFWarren = ({ symbol }) => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const previousSymbol = useRef('');
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState({});

  const toggleInfo = (type) => {
    setShowInfo(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const performAnalysis = useCallback(async (ticker) => {
    // Skip if already loading or if the symbol is the same as the previous one
    if (!ticker || loading || ticker === previousSymbol.current) {
      console.log('Skipping analysis:', { ticker, loading, previousSymbol: previousSymbol.current });
      return;
    }
    
    setLoading(true);
    setError(null);
    previousSymbol.current = ticker;

    try {
      console.log('Making request to backend for ticker:', ticker);
      
      // Get the base URL from environment variable or use default
      const baseUrl = process.env.REACT_APP_API_URL || '/api';
      const response = await fetch(`${baseUrl}/analyze_all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ticker: ticker,
          api_key: "oGNfp3JAE7yA27rrva4WyTSE3p2A7gKa"
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Raw data from backend:', data);  // Debug log
      
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid response format from server');
      }

      // Transform the results to use full names as keys
      const transformedResults = {};
      Object.entries(data).forEach(([key, value]) => {
        console.log('Processing backend key:', key, 'with value:', value);  // Debug log
        
        // Find the matching frontend display name
        const fullName = Object.entries(analysisTypeKeys).find(([_, v]) => v === key)?.[0];
        console.log('Mapped to frontend name:', fullName);  // Debug log
        
        if (fullName) {
          transformedResults[fullName] = {
            conditions_met: value.conditions_met,
            total_conditions: value.total_conditions,
            percentage: value.percentage,
            details: value.details
          };
        } else {
          console.warn(`No frontend mapping found for backend key: ${key}`);  // Warning for unmapped keys
        }
      });

      console.log('Transformed results:', transformedResults);
      setResults(transformedResults);
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError(error.message);
      const errorResults = {};
      analysisTypes.forEach(type => {
        errorResults[type] = { 
          error: 'Failed to fetch data', 
          details: error.message,
          conditions_met: 0,
          total_conditions: 0
        };
      });
      setResults(errorResults);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  React.useEffect(() => {
    if (symbol && symbol !== previousSymbol.current) {
      performAnalysis(symbol);
    }
  }, [symbol, performAnalysis]);

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
        <div className="p-6 text-center text-gray-500 dark:text-gray-400">
          Enter a stock symbol to view analysis
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 dark:bg-gray-900 w-full overflow-hidden rounded-xl shadow-lg">
      <section className="p-6">
        <header className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
            {error ? (
              <span className="text-red-500">Error: {error}</span>
            ) : (
              `Investment Guru Analysis for ${symbol}`
            )}
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Analysis based on investment strategies of legendary investors
          </p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {analysisTypes.map((type) => (
            <div key={type} className="relative group">
              <button
                onClick={() => toggleInfo(type)}
                className="absolute top-4 right-4 z-10 w-8 h-8 flex items-center justify-center rounded-full 
                         bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 
                         hover:bg-gray-200 dark:hover:bg-gray-600 
                         transition-all duration-300 opacity-0 group-hover:opacity-100"
                title={`Show ${type} analysis details`}
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              
              {showInfo[type] && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                  <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-hidden">
                    <div className="sticky top-0 p-6 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                        {type} Analysis Criteria
                      </h3>
                      <button
                        onClick={() => toggleInfo(type)}
                        className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
                      >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="p-6 overflow-y-auto max-h-[calc(90vh-5rem)]">
                      <p className="text-base text-gray-700 dark:text-gray-300 whitespace-pre-line">
                        {analysisDescriptions[type]}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              
              <InfoCardWarren
                title={type}
                loading={loading}
                conditionsMet={results[type]?.conditions_met || 0}
                totalConditions={results[type]?.total_conditions || 0}
                error={results[type]?.error}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CardFWarren;
