import React, { useState, useEffect } from "react";
import Header from "./Header";
import Overview from "./Overview";
import CardFWarren from "./warren_peter";
import ChartSwitcher from "./chartswitch";
import CompanyOutlookTable from "./livechartdescription";
import Description from "./Description";
import PeerComparisonTable from "./PeerComparisonTable";
import MarketMovers from "./MarketMovers";
import { MarketProvider } from "../context/MarketContext";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// Initialize TimeAgo
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const Dashboard = () => {
  const [symbol, setSymbol] = useState("");
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const fetchPriceData = async () => {
      if (!symbol) return;
      
      setLoading(true);
      try {
        const response = await fetch(
          `https://financialmodelingprep.com/api/v3/quote/${symbol}?apikey=oGNfp3JAE7yA27rrva4WyTSE3p2A7gKa`
        );
        const data = await response.json();
        if (data && data.length > 0) {
          setPriceData(data[0]);
          setLastUpdated(new Date());
        }
      } catch (error) {
        console.error("Error fetching price data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPriceData();
    // Poll for price updates every minute
    const interval = setInterval(fetchPriceData, 60000);
    return () => clearInterval(interval);
  }, [symbol]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(price);
  };

  const formatChange = (change, changePercent) => {
    const isPositive = change > 0;
    const changeText = formatPrice(Math.abs(change));
    const percentText = Math.abs(changePercent).toFixed(2);
    return {
      text: `${isPositive ? "+" : "-"}${changeText} (${isPositive ? "+" : "-"}${percentText}%)`,
      isPositive,
    };
  };

  const getMarketStatus = (status) => {
    const isOpen = status?.toLowerCase().includes("open");
    return {
      text: status || "Market Closed",
      color: isOpen ? "bg-green-500" : "bg-red-500",
      textColor: isOpen ? "text-green-600 dark:text-green-400" : "text-red-600 dark:text-red-400",
    };
  };

  // Loading animation component with smooth transitions
  const LoadingPulse = () => (
    <div className="flex flex-col items-end gap-2 transition-opacity duration-300">
      <div className="flex flex-col items-end gap-1">
        <div className="h-9 w-36 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse-smooth"></div>
        <div className="h-6 w-48 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse-smooth delay-75"></div>
      </div>
      <div className="h-4 w-32 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse-smooth delay-150"></div>
    </div>
  );

  // Price Display Component
  const PriceDisplay = ({ data, lastUpdated }) => {
    const { text: marketStatus, color, textColor } = getMarketStatus(data.marketStatus);

    return (
      <div className="flex flex-col items-end gap-2 transition-opacity duration-300">
        <div className="flex flex-col items-end gap-1">
          <div className="text-3xl font-bold text-gray-900 dark:text-gray-100 transition-all duration-300 ease-in-out">
            {formatPrice(data.price)}
          </div>
          <div className="flex items-center gap-2">
            {(() => {
              const { text, isPositive } = formatChange(data.change, data.changesPercentage);
              return (
                <span
                  className={`${
                    isPositive ? "text-green-500 dark:text-green-400" : "text-red-500 dark:text-red-400"
                  } font-semibold transition-all duration-300`}
                >
                  {text}
                </span>
              );
            })()}
          </div>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="flex items-center gap-2">
            <span className={`w-2 h-2 rounded-full ${color}`}></span>
            <span className={`${textColor} font-medium`}>{marketStatus}</span>
          </div>
          <span className="text-gray-500 dark:text-gray-400">
            · Updated {lastUpdated ? timeAgo.format(lastUpdated) : "just now"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <MarketProvider>
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 transition-colors duration-200">
        {/* Header Section */}
        <div className="sticky top-0 z-50 w-full backdrop-blur-md bg-white/80 dark:bg-gray-900/80 border-b border-gray-200 dark:border-gray-700">
          <Header setSymbol={setSymbol} />
        </div>

        {/* Main Content */}
        <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 space-y-8 font-afacad max-w-7xl">
          {/* If NO symbol: Show Market Movers at top */}
          {!symbol && (
            <div className="space-y-4">
              <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-gray-100">
                Market Movers
              </h2>
              <MarketMovers onSelectStock={setSymbol} />
            </div>
          )}

          {/* If symbol is present: Show Title, Price, etc. */}
          {symbol && (
            <div className="space-y-6">
              {/* Title, Stock Price, and Description Section */}
              <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg border border-gray-100 dark:border-gray-700 transition-all duration-300">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6">
                  {/* Left side: Symbol, Name, Description */}
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col">
                      <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-gray-100">
                        {priceData?.name || symbol}
                      </h1>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {priceData?.exchange} · {priceData?.sector}
                      </span>
                    </div>
                    <Description symbol={symbol} />
                  </div>

                  {/* Right side: Price & Market Status */}
                  <div className="min-h-[100px] flex items-center">
                    {loading ? (
                      <LoadingPulse />
                    ) : priceData ? (
                      <PriceDisplay data={priceData} lastUpdated={lastUpdated} />
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Analysis Section - Guru Cards */}
              <div className="space-y-6 animate-fadeIn">
                <CardFWarren symbol={symbol} />
              </div>

              {/* Chart Section */}
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 p-6 animate-fadeIn">
                <ChartSwitcher symbol={symbol} />
              </div>

              {/* Overview Section */}
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 p-6 animate-fadeIn">
                <Overview symbol={symbol} />
              </div>

              {/* Market Movers Section - Show after Overview when symbol is entered */}
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 p-6 animate-fadeIn">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-6">
                  Market Performance Overview
                </h2>
                <MarketMovers onSelectStock={setSymbol} />
              </div>

              {/* Peer Comparison Section */}
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 p-6 overflow-x-auto animate-fadeIn">
                <PeerComparisonTable symbol={symbol} />
              </div>
            </div>
          )}
        </main>

        {/* Footer */}
        <footer className="mt-12 py-6 px-4 text-center text-sm text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 transition-colors duration-200">
          <div className="container mx-auto max-w-7xl">
            <p className="font-medium">© 2024 Createfy. All rights reserved.</p>
          </div>
        </footer>
      </div>

      {/* Add these styles to your CSS or Tailwind config */}
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out forwards;
        }
        @keyframes pulse-smooth {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
        .animate-pulse-smooth {
          animation: pulse-smooth 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
        .delay-75 {
          animation-delay: 75ms;
        }
        .delay-150 {
          animation-delay: 150ms;
        }
      `}</style>
    </MarketProvider>
  );
};

export default Dashboard;
