// Technical Analysis Utility Functions

// Calculate Simple Moving Average (SMA)
export const calculateSMA = (data, period) => {
    const sma = [];
    for (let i = 0; i < data.length; i++) {
        if (i < period - 1) {
            sma.push(null);
            continue;
        }
        const sum = data.slice(i - period + 1, i + 1).reduce((a, b) => a + b, 0);
        sma.push(sum / period);
    }
    return sma;
};

// Calculate Exponential Moving Average (EMA)
export const calculateEMA = (data, period) => {
    const ema = [];
    const multiplier = 2 / (period + 1);

    // First EMA uses SMA as initial value
    let initialSMA = data.slice(0, period).reduce((a, b) => a + b, 0) / period;
    ema.push(initialSMA);

    for (let i = 1; i < data.length; i++) {
        const currentEMA = (data[i] - ema[i-1]) * multiplier + ema[i-1];
        ema.push(currentEMA);
    }
    return ema;
};

// Calculate Relative Strength Index (RSI)
export const calculateRSI = (data, period = 14) => {
    const rsi = [];
    const gains = [];
    const losses = [];

    // Calculate price changes and separate gains and losses
    for (let i = 1; i < data.length; i++) {
        const change = data[i] - data[i-1];
        gains.push(change > 0 ? change : 0);
        losses.push(change < 0 ? Math.abs(change) : 0);
    }

    // Calculate initial average gain and loss
    let avgGain = gains.slice(0, period).reduce((a, b) => a + b, 0) / period;
    let avgLoss = losses.slice(0, period).reduce((a, b) => a + b, 0) / period;

    // Calculate initial RSI
    rsi.push(100 - (100 / (1 + avgGain / avgLoss)));

    // Calculate subsequent RSI values
    for (let i = period; i < data.length - 1; i++) {
        avgGain = ((avgGain * (period - 1)) + gains[i]) / period;
        avgLoss = ((avgLoss * (period - 1)) + losses[i]) / period;
        rsi.push(100 - (100 / (1 + avgGain / avgLoss)));
    }

    return rsi;
};

// Calculate MACD (Moving Average Convergence Divergence)
export const calculateMACD = (data, fastPeriod = 12, slowPeriod = 26, signalPeriod = 9) => {
    // Calculate fast and slow EMAs
    const fastEMA = calculateEMA(data, fastPeriod);
    const slowEMA = calculateEMA(data, slowPeriod);

    // Calculate MACD line
    const macdLine = fastEMA.map((fast, i) => fast - slowEMA[i]);

    // Calculate signal line (9-day EMA of MACD line)
    const signalLine = calculateEMA(macdLine, signalPeriod);

    // Calculate MACD histogram
    const histogram = macdLine.map((macd, i) => macd - signalLine[i]);

    return {
        macdLine,
        signalLine,
        histogram
    };
};

// Calculate Bollinger Bands
export const calculateBollingerBands = (data, period = 20, standardDeviations = 2) => {
    const middleBand = calculateSMA(data, period);
    const upperBand = [];
    const lowerBand = [];

    for (let i = 0; i < data.length; i++) {
        if (i < period - 1) {
            upperBand.push(null);
            lowerBand.push(null);
            continue;
        }

        const slice = data.slice(i - period + 1, i + 1);
        const sum = slice.reduce((a, b) => a + b, 0);
        const mean = sum / period;
        
        const squaredDiffs = slice.map(x => Math.pow(x - mean, 2));
        const variance = squaredDiffs.reduce((a, b) => a + b, 0) / period;
        const standardDeviation = Math.sqrt(variance);

        upperBand.push(middleBand[i] + (standardDeviation * standardDeviations));
        lowerBand.push(middleBand[i] - (standardDeviation * standardDeviations));
    }

    return {
        upperBand,
        middleBand,
        lowerBand
    };
};

// Generate technical analysis signals
export const generateSignals = (data) => {
    const rsi = calculateRSI(data);
    const macd = calculateMACD(data);
    const bb = calculateBollingerBands(data);
    const lastRSI = rsi[rsi.length - 1];
    const lastMACD = macd.histogram[macd.histogram.length - 1];
    const lastPrice = data[data.length - 1];
    const lastBB = {
        upper: bb.upperBand[bb.upperBand.length - 1],
        middle: bb.middleBand[bb.middleBand.length - 1],
        lower: bb.lowerBand[bb.lowerBand.length - 1]
    };

    const signals = {
        rsi: {
            value: lastRSI,
            signal: lastRSI > 70 ? 'Overbought' : lastRSI < 30 ? 'Oversold' : 'Neutral'
        },
        macd: {
            value: lastMACD,
            signal: lastMACD > 0 ? 'Bullish' : 'Bearish'
        },
        bollingerBands: {
            value: lastPrice,
            signal: lastPrice > lastBB.upper ? 'Overbought' : 
                   lastPrice < lastBB.lower ? 'Oversold' : 'Neutral'
        }
    };

    return signals;
};